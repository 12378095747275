

























































import { Component } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { DeviceTypeEntityModel } from '@common-src/entity-model/device-type-entity';
import { ThingsFunctionType, ViewModeType } from '@common-src/model/enum';
import FunctionIndexComponent from './function-index.vue';
import DeviceTypeService from '@common-src/service3/device-type';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ModuleType } from '@common-src/enum/module-type.enum';

const deviceService = new DeviceTypeService();

@Component({
    components: {
        'function-index-component': FunctionIndexComponent
    }
})
export default class DeviceTypeDetailDrawer extends DrawerComponent {
    deviceTypeModel: DeviceTypeEntityModel = null;
    tabIndex: string = '1';
    deviceId: string = null;

    drawerShow(model: DeviceTypeEntityModel) {
        this.drawerOpen(model.id, model.name);
        this.deviceTypeModel = model;
        this.init(model.id);
    }

    init(deviceId: string) {
        this.deviceId = deviceId;
        this.startFullScreenLoading();
        deviceService.retrieve(deviceId)
            .then(res => {
                this.deviceTypeModel = res;
            })
            .catch(error => {
                this.deviceTypeModel = new DeviceTypeEntityModel();
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }

    reload() {
        // this.tabIndex = '1';
        this.init(this.deviceId);
    }
    drawerClose() {
        this.drawerVisible = false;
        this.deviceId = null;
        this.tabIndex = '1';
    }
    editClick() {
        (this.$refs.formDialog as FormDialogComponent<DeviceTypeEntityModel>).dialogOpen(this.deviceTypeModel, deviceService as any, ViewModeType.UPDATE);
    }
}
