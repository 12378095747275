






















import { Component, Prop, Watch } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { FormControlOptionModel } from '@common-src/model/form-control';
import DriverConfigComponent from '../device-driver/driver-config.vue';
import { ViewModeType, AttributeType } from '@common-src/model/enum';
import { ThingsModelTypeModel } from '@common-src/entity-model/things-model-type-entity';
import DeviceDriverService from '@common-src/service3/device-driver';

@Component({
    components: {
        DriverConfigComponent
    }
})
export default class DeviceTypeAttributeDialog extends FormDialogComponent<any> {
    driverList: Array<any> = null;
    thingsModel: ThingsModelTypeModel = new ThingsModelTypeModel();
    disabled: boolean = false;
    attributeType: AttributeType = null;
    AttributeType = AttributeType;

    @Prop({ default: 'large' })
    size: string;
    formOnChange(formControlModel: FormControlOptionModel) {
        this.attributeType = this.jtlForm.formModel.attributeType;
        this.$emit('change', formControlModel);
    }

    /**
     * 打开弹窗
     * @param model 数据模型
     * @param service 增删改查服务
     * @param needGetDetail 是否需要调用详情接口
     */
    attributeDialogOpen(model: any, service: any, viewMode: ViewModeType, needGetDetail): any {
        this.attributeType = model.attributeType;
        this.dialogOpen(model, service, viewMode, needGetDetail);
        this.thingsModel = model.thingsModelTypeModel;
        viewMode === ViewModeType.VIEW ? this.disabled = true : this.disabled = false;
        if (model.id) {
            DeviceDriverService.getProductAttributeDriverDetail(model.id).then(res => {
                this.driverList = res;
            });
        } else if (model.entityId) {
            DeviceDriverService.getProductDriverDetail(model.entityId).then(res => {
                this.driverList = res;
            });
        }
    }

    /**
     * 点击弹窗保存事件
     * 提交表单数据
     */
    async saveAttribute(): Promise<any> {
        if (!this.jtlForm) {
            return;
        }
        if (this.$refs.driverConfigs) {
            const driverConfigs = [];
            for (let i = 0; i < (this.$refs.driverConfigs as any).length; i++) {
                driverConfigs.push(await this.$refs.driverConfigs[i].getDriverConfig());
            }
            this.jtlForm.formModel.attrDriver = driverConfigs;
        }

        return this.jtlForm.submitForm().then((ret) => {
            this.attributeDialogClose();
            switch (this.viewMode) {
                case ViewModeType.NEW:
                    this.$emit('dialogOK', { viewMode: this.viewMode, res: Object.assign({}, this.jtlForm.formModel, { id: ret }) });
                    break;
                case ViewModeType.UPDATE:
                    this.$emit('dialogOK', { viewMode: this.viewMode, res: this.jtlForm.formModel });
                    break;
            }
            this.showMessageSuccess('保存成功');
            // this.$emit('dialogOK', { viewMode: this.viewMode, res: ret });
        }).catch(err => {
            throw err;
        });
    }

    /**
     * form 选择数据返回 重新更新数据转换
     */
    backData(formModel: any) {
        this.thingsModel = this.jtlForm.formModel.thingsModelTypeModel;
        this.$nextTick(() => {
            if (this.$refs.driverConfigs) {
                _.forEach(this.$refs.driverConfigs, (item: DriverConfigComponent) => {
                    item.initAttrData();
                });
            }
        });
    }

    attributeDialogClose() {
        this.driverList = null;
        this.dialogClose();
    }
}
