var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      class: "form-edit-component form-edit-component-" + _vm.size,
      attrs: { title: _vm.dialogTitle, visible: _vm.dialogVisible },
      on: { cancel: _vm.attributeDialogClose },
    },
    [
      _c(
        "div",
        { staticClass: "jtl-entity-dialog-component" },
        [
          _c("jtl-form", {
            ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME,
            on: { change: _vm.formOnChange, backData: _vm.backData },
          }),
          _vm.attributeType !== _vm.AttributeType.SERVER
            ? _c(
                "a-tabs",
                { attrs: { type: "card" } },
                _vm._l(_vm.driverList, function (item) {
                  return _c(
                    "a-tab-pane",
                    {
                      key: item.identifier,
                      attrs: { tab: item.name, "force-render": "" },
                    },
                    [
                      _c("DriverConfigComponent", {
                        ref: "driverConfigs",
                        refInFor: true,
                        attrs: {
                          "driver-config": item,
                          "things-model": _vm.thingsModel,
                          disabled: _vm.disabled,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.attributeDialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _vm.viewMode !== _vm.ViewModeType.VIEW
            ? _c(
                "jtl-button",
                { attrs: { type: "primary", "click-prop": _vm.saveAttribute } },
                [_vm._v("保存")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }