












import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { ThingsModelType } from '@common-src/model/enum';
import { ThingsModelTypeModel } from '@common-src/entity-model/things-model-type-entity';
import { FormControlModel, FormControlOptionModel, FormControlType, FormControlDataAttrModel } from '@common-src/model/form-control';
@Component
export default class DriverConfigComponent extends BaseComponent {
    @Prop()
    type: string;
    @Prop()
    driverConfig: any;
    @Prop()
    thingsModel: any;
    @Prop({
        default: false
    })
    disabled: boolean;
    selectAttrTypeOption: any = null;

    get enumCloneList() {
        return this.thingsModel.enumList;
    }

    mounted() {
        this.init();
    }

    get HasAttrType(): boolean {
        return this.driverConfig.attrTypeList && this.driverConfig.attrTypeList.length > 0;
    }

    init() {
        if (this.HasAttrType) {
            const addressControlList: Array<FormControlModel<string>> = [{
                key: 'address',
                label: '驱动地址',
                type: FormControlType.TEXT,
                disabled: true,
                value: _.get(this.driverConfig, 'driverAddress')
            }];
            (this.$refs.formAddress as any).initFormByControls(addressControlList);

            const attrType = _.get(this.driverConfig, 'attrType');
            const attrTypeControlList: Array<any> = [{
                key: 'attrType',
                label: '属性类型',
                type: FormControlType.SELECT,
                disabled: this.disabled,
                options: this.driverConfig.attrTypeList,
                value: attrType,
                required: true
            }];
            if (attrType) {
                this.formAttrTypeChange({ options: this.driverConfig.attrTypeList } as any);
                this.$nextTick(() => {
                    this.formAttrChange();
                });
            }
            (this.$refs.formAttrType as any).initFormByControls(attrTypeControlList);
        } else if (this.driverConfig.variables && this.driverConfig.variables.length > 0) {
            const data = _.map(this.driverConfig.variables, r => {
                return {
                    ...r,
                    required: false
                };
            });
            (this.$refs.formAttr as any).initFormByControls(data);
        }
        this.initAttrData();
    }

    formAttrTypeChange(formControlModel: FormControlOptionModel) {
        (this.$refs.formAddress as any).formModel.address = '';
        this.$nextTick(() => {
            this.selectAttrTypeOption = _.find(formControlModel.options, (item: any) => item.value === (this.$refs.formAttrType as any).formModel.attrType);
            const controlList = _.map(_.get(this.selectAttrTypeOption, 'children') || [], el => {
                return { ...el, disabled: this.disabled, required: false };
            });
            // const controlList = _.get(this.selectAttrTypeOption, 'children');
            (this.$refs.formAddress as any).formModel.address = _.get(this.selectAttrTypeOption, 'format');
            (this.$refs.formAttr as any).initFormByControls(controlList);
            this.formAttrChange();
        });
    }

    formAttrChange() {
        if (this.selectAttrTypeOption && this.selectAttrTypeOption.format) {
            let address: string = this.selectAttrTypeOption.format;
            _.forEach(this.selectAttrTypeOption.children, (item: any) => {
                // if ((this.$refs.formAttr as any).formModel[item.key]) {
                //     address = address.replace(`{${item.key}}`, (this.$refs.formAttr as any).formModel[item.key]);
                // }
                address = address.replace(`{${item.key}}`, (this.$refs.formAttr as any).formModel[item.key] || '');
            });
            (this.$refs.formAddress as any).formModel.address = address;
        }
    }

    async getDriverConfig() {
        if (!await (this.$refs.formAttrType as any).validate()) {
            this.showMessageError('请检查您提交的数据');
            throw new Error('请检查您提交的数据');
        }
        /** 这里防止信息重复提示 */
        if (!await (this.$refs.formCommon as any).validate()) {
            throw new Error('请检查您提交的数据');
        }

        let dataConversionExpression = null;
        if (this.thingsModel.thingsModelType === ThingsModelType.BOOLEAN) {
            if (_.get(this, 'driverConfig.dataFalseValue') && _.get(this, 'driverConfig.dataTrueValue')) {
                dataConversionExpression = {
                    matchType: 'Equal',
                    expression: null,
                    mapRules: [
                        { source: _.get(this, 'driverConfig.dataFalseValue'), target: 'false' },
                        { source: _.get(this, 'driverConfig.dataTrueValue'), target: 'true' }
                    ]
                };
            } else if (_.get(this, 'driverConfig.dataFalseValue')) {
                dataConversionExpression = {
                    matchType: 'Equal',
                    expression: null,
                    mapRules: [
                        { source: _.get(this, 'driverConfig.dataFalseValue'), target: 'false' }
                    ]
                };
            } else if (_.get(this, 'driverConfig.dataTrueValue')) {
                dataConversionExpression = {
                    matchType: 'Equal',
                    expression: null,
                    mapRules: [
                        { source: _.get(this, 'driverConfig.dataTrueValue'), target: 'true' }
                    ]
                };
            }
        } else if (this.thingsModel.thingsModelType === ThingsModelType.ENUM) {
            if (this.driverConfig.currentEnumList.length) {
                let mapRulesEnum = [];
                _.forEach(this.driverConfig.currentEnumList, item => {
                    const source = item.source;
                    const target = item.target;
                    mapRulesEnum.push({ source, target });
                });
                dataConversionExpression = {
                    matchType: 'Equal',
                    expression: null,
                    mapRules: mapRulesEnum
                };
            }
        } else if (this.thingsModel.thingsModelType === ThingsModelType.INT || this.thingsModel.thingsModelType === ThingsModelType.FLOAT || this.thingsModel.thingsModelType === ThingsModelType.DOUBLE) {
            const decimalDigit = _.get(this, 'driverConfig.mathOperations[0].params.decimalDigit');
            dataConversionExpression = {
                matchType: 'Math',
                expression: _.get(this, 'driverConfig.expressionOther'),
                mathOperations: [
                    {
                        name: 'Round',
                        params: {
                            decimalDigit: _.isEmpty(decimalDigit) ? null : decimalDigit,
                            roundType: _.get(this, 'driverConfig.mathOperations[0].params.roundType') || null
                        }
                    },
                    {
                        name: 'Absolute',
                        params: _.get(this, 'driverConfig.mathOperations[1].params')
                    }
                ]
            };
        } else {
            dataConversionExpression = {
                matchType: 'Other',
                mapRules: [],
                expression: _.get(this, 'driverConfig.expressionOther')
            };
        }

        return {
            identifier: _.get(this, 'driverConfig.identifier'),
            name: _.get(this, 'driverConfig.name'),
            coefficient: _.get(this.$refs, 'formCommon.formModel.ratio'),
            // dataConversionExpression: _.get(this.$refs, 'formCommon.formModel.expression'),
            dataConversionExpression: dataConversionExpression,
            pollInterval: _.get(this.$refs, 'formCommon.formModel.pollInterval'),
            driverObjectTypePropIdentifier: _.get(this.$refs, 'formAttrType.formModel.attrType'),
            variables: _.get(this.$refs, 'formAttr.formModel')
        };
    }

    initAttrData() {
        const modelType = [ThingsModelType.INT, ThingsModelType.FLOAT, ThingsModelType.DOUBLE];
        const commonControlList: Array<any> = [
            {
                key: 'ratio',
                label: '系数',
                disabled: this.disabled,
                type: FormControlType.NUMBER,
                invisibleFunction: () => !_.includes(modelType, this.thingsModel.thingsModelType),
                value: _.includes(modelType, this.thingsModel.thingsModelType) ? _.get(this.driverConfig, 'ratio') || 1 : null
            },
            {
                key: 'pollInterval',
                label: '采集周期',
                type: FormControlType.NUMBER,
                message: '秒',
                min: 0,
                disabled: this.disabled,
                noMessageTooltip: true,
                value: _.get(this.driverConfig, 'pollInterval')
            },
            {
                key: 'expression',
                label: '数据转换',
                type: FormControlType.FORM_DATA_DES,
                enumCloneList: this.enumCloneList,
                dataTrueName: this.thingsModel.dataTrueName,
                dataFalseName: this.thingsModel.dataFalseName,
                thingsType: this.thingsModel.thingsModelType,
                disabled: this.disabled,
                value: this.driverConfig
            }
        ];
        (this.$refs.formCommon as any).initFormByControls(commonControlList);
    }
}
