import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import http from '@common-src/service3/http/axios';
import { ThingsAttributeEntityModel, ThingsAttributeQueryModel } from '@common-src/entity-model/things-attribute-entity';
// import { ApiMap, getApiMap } from './api-map';
// import { DeviceTypeEnum } from '@common-src/model/enum';
const baseUrl = `${IOT_BASE_REQUEST_PATH}/product`;
export default class ThingsAttributeService extends BaseCRUDQService<ThingsAttributeEntityModel, ThingsAttributeQueryModel> {
    constructor(baseUrl = ThingsAttributeEntityModel.baseUrl) {
        super(baseUrl, ThingsAttributeEntityModel);
    }

    // static async getTemplateOptions(): Promise<any> {
    //     const res = await http.request<Array<any>>({
    //         url: ApiMap.getAllThingsTemplate,
    //         method: 'GET'
    //     });
    //     const options = _.map(res, item => {
    //         return {
    //             name: `${item.name} | ${item.identifier}`,
    //             value: item.id
    //         };
    //     });
    //     return options;
    // }

    // static async getDeviceTypeOptions(types?: DeviceTypeEnum): Promise<any> {
    //     const params = { types };
    //     const res = await http.request<Array<any>>({
    //         url: ApiMap.getAllDeviceTypeList,
    //         method: 'POST',
    //         params
    //     });
    //     const options = _.map(_.filter(res, item => item.type), item => {
    //         return {
    //             name: item.name,
    //             value: item.id,
    //             type: item.type
    //         };
    //     });
    //     return options;
    // }

    // static async getDeviceTypeAttrs(deviceTypeId: string):Promise<any> {
    //     if (!deviceTypeId) {
    //         return;
    //     }
    //     const res = await http.request<Array<any>>({
    //         url: getApiMap('getDeviceTypeAttrs', { deviceTypeId }),
    //         method: 'GET'
    //     });
    //     return _.map(res, item => ({
    //         name: `${_.get(item, 'definition.name')} | ${_.get(item, 'identifier')}`,
    //         value: `${_.get(item, 'identifier')}`
    //     }));
    // }

    async updateUsing(params: { enabled: boolean, id: string }):Promise<ThingsAttributeEntityModel> {
        const url = `${baseUrl}/attr/updateEnabled`;
        const res = await http.request<any>({
            url: url,
            method: 'POST',
            params
        });
        return res;
    }
}
