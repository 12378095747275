var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "driver-config-component" },
    [
      _c("jtl-form", { ref: "formAddress" }),
      _vm.HasAttrType
        ? _c("div", { staticClass: "form-title" }, [_vm._v("对象属性类型")])
        : _vm._e(),
      _c("jtl-form", {
        ref: "formAttrType",
        on: { change: _vm.formAttrTypeChange },
      }),
      _vm.selectAttrTypeOption
        ? _c("div", { staticClass: "form-title" }, [_vm._v("专有配置")])
        : _vm._e(),
      _c("jtl-form", { ref: "formAttr", on: { change: _vm.formAttrChange } }),
      _c("div", { staticClass: "form-title" }, [_vm._v("通用配置")]),
      _c("jtl-form", { ref: "formCommon" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }