




































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { ThingsFunctionEntityModel } from '@common-src/entity-model/things-function-entity';
import { AttributeEntityType, ThingsFunctionType, ViewModeType, ThingsEntityType, EntityStatus } from '@common-src/model/enum';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import ThingsAttributeService from '@common-src/service3/things-attribute';
import ThingsExtendAttributeService from '@common-src/service3/things-extend-attribute';
import ThingsEventService from '@common-src/service3/things-event';
import ThingsMethodService from '@common-src/service3/things-method';
import DeviceTypeService from '@common-src/service/device-type';
import DeviceTypeService3 from '@common-src/service3/device-type';
import { ThingsAttributeEntityModel } from '@common-src/entity-model/things-attribute-entity';
import { ThingsExtendAttributeEntityModel } from '@common-src/entity-model/things-extend-attribute-entity';
import { ThingsEventEntityModel } from '@common-src/entity-model/things-event-entity';
import { ThingsMethodEntityModel } from '@common-src/entity-model/things-method-entity';
import ThingsFunctionEditComponent from '@common-src/pages/dashboard/things-function/things-function-edit.vue';
import { DeviceTypeEntityModel } from '@common-src/entity-model/device-type-entity';
import DeviceTypeAttributeDialog from './device-type-attribute.dialog.vue';

const thingsAttributeService = new ThingsAttributeService();
const thingsEventService = new ThingsEventService();
const thingsMethodService = new ThingsMethodService();
const thingsExtendAttributeService = new ThingsExtendAttributeService();
// 全局属性 --只有六个且根据前两个字符为Nb判断时健壮性较差
const GlobalAttribte = [
    'NbRunningStatus',
    'NbRunningParam',
    'NbControlStatus',
    'NbControlParam',
    'NbFaultStatus',
    'NbFaultParam'
];
const EnableStatus = [
    { value: '', name: '全部属性' },
    { value: EntityStatus.ENABLE, name: '已启用' },
    { value: EntityStatus.DISABLE, name: '未启用' }
];
@Component({
    components: {
        'things-function-edit-dialog': ThingsFunctionEditComponent,
        DeviceTypeAttributeDialog
    }
})
export default class FunctionIndexComponent extends BaseComponent {
    AttributeEntityType = AttributeEntityType;
    ThingsFunctionType = ThingsFunctionType;
    ViewModeType = ViewModeType;
    ThingsEntityType = ThingsEntityType;
    currentFunction: ThingsFunctionType = ThingsFunctionType.ATTRIBUTE;
    enable: string = 'true';
    enableStatus = EnableStatus;
    FilterDataList: Array<any> = [];
    @Prop()
    deviceTypeModel: DeviceTypeEntityModel;

    get FunctionName(): string {
        return this.deviceTypeModel.getFunctionName(this.currentFunction);
    }

    get TableColumns(): Array<any> {
        return _.filter(
            ThingsFunctionEntityModel.getFunctionTabelColumns(),
            (item: any) => {
                if (this.currentFunction === ThingsFunctionType.METHOD || this.currentFunction === ThingsFunctionType.EVENT) {
                    return (_.isUndefined(item.functionType) || item.functionType.indexOf(this.currentFunction) > -1) &&
                     (item.dataIndex !== 'enabled');
                } else {
                    return (_.isUndefined(item.functionType) || item.functionType.indexOf(this.currentFunction) > -1);
                }
            }
        );
    }

    @Watch('currentFunction', { immediate: true })
    @Watch('deviceTypeModel', { immediate: true })
    handleWatch() {
        if (this.currentFunction === ThingsFunctionType.ATTRIBUTE) {
            this.onSelectChange(this.enable);
        } else {
            this.FilterDataList = this.deviceTypeModel.getFunctionList(this.currentFunction);
        }
    }

    // get FilterDataList(): Array<any> {
    //     if (this.currentFunction === ThingsFunctionType.ATTRIBUTE) {
    //         DeviceTypeService3.getAttrs(this.deviceTypeModel.id, event).then((res) => {
    //             return _.map(res, item => new ThingsAttributeEntityModel().toModel(item));
    //         });
    //     }
    //     return this.deviceTypeModel.getFunctionList(this.currentFunction);
    // }
    onSelectChange(event) {
        DeviceTypeService3.getAttrs(this.deviceTypeModel.id, event).then((res) => {
            this.FilterDataList = _.map(res, item => new ThingsAttributeEntityModel().toModel(item));
        });
    }
    functionItemClick(model: any, viewMode: ViewModeType) {
        switch (this.currentFunction) {
            case ThingsFunctionType.ATTRIBUTE:
                (this.$refs.attributeFormDialog as any)
                    .attributeDialogOpen(model || new ThingsAttributeEntityModel(ThingsEntityType.DEVICE_TYPE, this.deviceTypeModel.id), thingsAttributeService as any, viewMode);
                break;
            case ThingsFunctionType.METHOD:
                (this.$refs.methodFormDialog as FormDialogComponent<ThingsMethodEntityModel>)?.dialogOpen(model || new ThingsMethodEntityModel(ThingsEntityType.DEVICE_TYPE, this.deviceTypeModel.id), thingsMethodService as any, viewMode);
                break;
            case ThingsFunctionType.EVENT:
                (this.$refs.eventFormDialog as FormDialogComponent<ThingsEventEntityModel>)?.dialogOpen(model || new ThingsEventEntityModel(ThingsEntityType.DEVICE_TYPE, this.deviceTypeModel.id), thingsEventService as any, viewMode);
                break;
            case ThingsFunctionType.EXTENDATTRIBUTE:
                (this.$refs.attributeFormDialog as any)
                    .attributeDialogOpen(model || new ThingsExtendAttributeEntityModel(ThingsEntityType.DEVICE_TYPE, this.deviceTypeModel.id), thingsExtendAttributeService as any, viewMode);
                break;
        }
    }

    addFunctionClick() {
        if (this.currentFunction === ThingsFunctionType.FEATURE) {
            (this.$refs.functionEditFormDialog as any).dialogOpen(this.currentFunction, this.FilterDataList);
        } else {
            this.functionItemClick(null, ViewModeType.NEW);
        }
    }

    validateGlobalAttrbite(text:string) {
        return _.includes(GlobalAttribte, text);
    }

    deleteFunctionClick(model: any) {
        let deleteService: (id: string) => Promise<any> = null;
        switch (this.currentFunction) {
            case ThingsFunctionType.ATTRIBUTE:
            case ThingsFunctionType.EXTENDATTRIBUTE:
                deleteService = thingsAttributeService.delete.bind(thingsAttributeService);
                break;
            case ThingsFunctionType.METHOD:
                deleteService = thingsMethodService.delete.bind(thingsMethodService);
                break;
            case ThingsFunctionType.EVENT:
                deleteService = thingsEventService.delete.bind(thingsEventService);
                break;
        }
        this.startFullScreenLoading('正在删除...');
        deleteService(model.id)
            .then(res => {
                this.$emit('reload');
                this.handleWatch();
            }).finally(() => {
                this.stopFullScreenLoading();
            });
    }

    formDialogOK() {
        this.handleWatch();
        this.$emit('reload');
    }

    syncFunctionClick() {
        DeviceTypeService.syncDeviceTypeAttribute(this.deviceTypeModel.id).then(() => {
            this.showMessageSuccess('更新设备成功');
        });
    }

    enableOnChange(property: ThingsAttributeEntityModel) {
        const param = {
            enabled: property.enabled,
            id: property.id
        };
        thingsAttributeService.updateUsing(param).then(() => {
            property.enabled = !property.enabled;
            this.onSelectChange(this.enable);
        }).catch(err => {
            console.log(err);
        });
    }
}
